<template>
  <div>
    <el-dialog
        v-model="addExperDialog"
        title="有问题啦"
        width="50%"
        :close-on-click-modal="false"
        :show-close="false"
    >
      <el-form :model="formMessage" label-width="120px">
        <el-form-item label="专家姓名" prop="expertName">
          <el-input v-model="formMessage.expertName"/>
        </el-form-item>
        <el-form-item label="专业" prop="expertProfession">
          <el-input v-model="formMessage.expertProfession"/>
        </el-form-item>
        <el-form-item label="毕业院校" prop="graduateSchool">
          <el-input v-model="formMessage.graduateSchool"/>
        </el-form-item>
        <el-form-item label="学位/学历" prop="degreeEducation">
          <el-input v-model="formMessage.degreeEducation"/>
        </el-form-item>
        <el-form-item label="职务" prop="post">
          <el-input v-model="formMessage.post"/>
        </el-form-item>
        <el-form-item label="职业" prop="occupation">
          <el-input v-model="formMessage.occupation"/>
        </el-form-item>
        <el-form-item label="代表作" prop="representativeWork">
          <el-input v-model="formMessage.representativeWork"/>
        </el-form-item>
        <el-form-item label="主要成就" prop="majorAchievements">
          <el-input v-model="formMessage.majorAchievements"/>
        </el-form-item>
        <el-form-item label="个人简介" prop="briefIntroduction">
          <el-input v-model="formMessage.briefIntroduction"/>
        </el-form-item>
        <el-form-item label="专家分类" prop="expertFlag">
          <el-select v-model="formMessage.expertFlag" class="m-2" placeholder="请选择专家类别" size="large">
            <el-option
                v-for="item in expertFlags"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            />
          </el-select>
        </el-form-item>

      </el-form>
      <div>
        <el-upload
            ref="upload"
            class="upload-demo"
            action="https://www.cr12cz.cn/czscsms/up/upload"
            :auto-upload="false"
            :data="dataup"
            :on-change="imgchange"
            :on-remove="handleRemove"
            :multiple="false"
            :on-success="upsuccess"
            :limit="1"
        >
          <el-button type="primary" style="margin-left: 35%">请选择专家照片</el-button>
        </el-upload>
      </div>
      <template #footer>
          <span class="dialog-footer">
            <el-button @click="cancelDia()"> 取消 </el-button>
            <el-button type="primary" @click="addExpertMessage()"> 提交 </el-button>
          </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "addExpert",
  props: ['addExperDialog'],
  data() {
    return {
      dataup: {
        file: '',
        userid: ''
      },
      addExperDialog1: false,
      formMessage: {
        expertName: '',
        expertProfession: '',
        graduateSchool: '',
        degreeEducation: '',
        post: '',
        occupation: '',
        representativeWork: '',
        majorAchievements: '',
        briefIntroduction: '',
        expertFlag: '',
      },
      expertFlags: [
        {
          value: '0',
          label: '内部专家',
        },
        {
          value: '1',
          label: '外部专家',
        }
      ],
      fileList: []
    }
  },
  computed: {
    ...mapGetters(['GET_USERID']),
    ...mapGetters(['GET_USER']),
  },
  created() {

  },
  methods: {
    cancelDia(){
      this.$emit('NotshowExpert', this.addExperDialog1);
    },
    addExpertMessage() {
      this.dataup.file = this.fileList[0].raw;
      this.dataup.userid = this.GET_USERID;
      this.$refs.upload.submit();
    },
    imgchange(file) {
      this.fileList.push(file);
    },
    handleRemove(file) {
      for (let i = 0; i < this.fileList.length; i++) {
        if (this.fileList[i].raw.lastModified === file.raw.lastModified) {
          this.fileList.splice(i, 1)
        }
      }
    },
    upsuccess(response) {
      const that = this;
      if (response.obj) {
        this.axios.post("/expertMessage/addExpertMessage", (responseAdd) => {
          if (responseAdd.obj) {
            this.$emit('NotshowExpert', this.addExperDialog1);
            this.dataup.file = '';
            this.fileList = [];
          }
        }, {
          expertName: that.formMessage.expertName,
          expertProfession: that.formMessage.expertProfession,
          graduateSchool: that.formMessage.graduateSchool,
          degreeEducation: that.formMessage.degreeEducation,
          post: that.formMessage.post,
          occupation: that.formMessage.occupation,
          representativeWork: that.formMessage.representativeWork,
          majorAchievements: that.formMessage.majorAchievements,
          briefIntroduction: that.formMessage.briefIntroduction,
          expertFlag: that.formMessage.expertFlag,
          imageurl: response.obj
        })
      }
    }
  }
}
</script>

<style>

</style>